import React, { useState, useEffect } from 'react';
import { IsMobileDevice, CleanArrayImages } from "../utils/functions";
import SEO from '../components/seo';
import Img from 'gatsby-image';
import FooterCicerone from '../components/cicerone/FooterCicerone';
import SuccessRegister from '../components/cicerone/SuccessRegister';
import Form from '../components/cicerone/FormCicierone'
import styles from '../styles/cicerone.module.scss';
import BirthConsent from '../components/BirthConsent';
import Cookies from "js-cookie";
import { navigate, Redirect } from '@reach/router';

const hasConfirmed = () => {
  return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
    || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
};

const Cicerone = ({ data }) => {
  const [images, setImages] = useState({});
  const [isMobile, setIsMobile] = useState();
  const [toggle, setToggle] = useState(false);
  const [success, setSuccess] = useState(false);
  const [age, setAge] = useState(false)

  useEffect(()=>{
    if(hasConfirmed())setAge(true)
  },[])

  useEffect(() => {
    setIsMobile(IsMobileDevice());
    setImages(CleanArrayImages(data));
  }, [data, setIsMobile]);

  // console.log(images)
  // console.log(isMobile)
  const handleConsent = () =>{
    setAge(true)
  }

  return (
    <Redirect noThrow to="/" />
    // age ? (
    //   success
    //     ? (<>
    //       <div className={styles.successIn}>
    //         <SEO title={'Cicerone'} />
    //         <div className={styles.logoPatagoniaSuccess} onClick={()=>{navigate("/")}}>
    //           {images.Cicerone && <Img fluid={images.Cicerone['patagonia'].childImageSharp.fluid}/>}
    //         </div>
    //         <SuccessRegister success={success} setSuccess={setSuccess} setToggle={setToggle} toggle={toggle} />
    //       </div>
    //     </>)
    //     : (
    //       <div className={styles.cicerone}>
    //         <SEO title={'Cicerone'} />
    //         { isMobile && toggle ?
    //           (<div className={styles.formIn}>
    //             <div className={styles.logoPatagoniaForm} onClick={()=>{navigate("/")}}>
    //               {images.Cicerone && <Img fluid={images.Cicerone['patagonia'].childImageSharp.fluid} />}
    //             </div>
    //             <div className={styles.arrowForm} onClick={() => {
    //               console.log("ACA")
    //               setToggle(!toggle)
    //             }}>
    //               {images.Cicerone && <Img fluid={images.Cicerone['arrow'].childImageSharp.fluid} />}
    //             </div>
    //             <Form success={success} setSuccess={setSuccess} setToggle={setToggle} toggle={toggle} />
    //           </div>
    //           ) : (
    //             <>
    //               <SEO title={'Cicerone'} />
    //               <div className={styles.holder}>
    //                 <div className={styles.header} onClick={()=>{navigate("/")}}>
    //                   {images.Cicerone && <Img className={styles.logoPatagonia} fluid={images.Cicerone['patagonia'].childImageSharp.fluid} />}
    //                 </div>
    //                 <div className={styles.presentContainer}>
    //                   <span />
    //                   <div className={styles.flexRow}>
    //                     <div className={styles.title}>
    //                       <span />
    //                       <p className={styles.presentTitle} >Participá por un <span className={styles.courseDetailsTitle}>curso</span> para expandir tu concimiento <br /> cervecero</p>
    //                       <div className={styles.trazadoTitle}></div>
    //                     </div>
    //                     <div className={styles.description}>
    //                       <p>Convertite en un <span className={isMobile ? styles.courseDetailsDescription : styles.courseDetailsDescription}>"Anfitrión certificado </span>  <span className={styles.bold}> en cerveza" </span>
    //                         <br />
    //                         El curso <span className={`${styles.bold} ${global.beerDetailsTitle}`}>BeerSavvy Online </span>del reconocido Programa internacional de <span className={styles.bold}>Certificación Cicerone </span>brinda el conocimiento necesario para ganar tu primera certificación. <span  >Incluye el examen</span> de "Anfitrión Certificado en cerveza".</p>
    //                       <div className={styles.logosContainer}>
    //                         {images.Cicerone && <Img className={styles.logo} fluid={images.Cicerone['logo'].childImageSharp.fluid} />}
    //                         {images.Cicerone && <Img className={styles.logo} fluid={images.Cicerone['beersaavy'].childImageSharp.fluid} />}
    //                         {images.Cicerone && <Img className={styles.logo} fluid={images.Cicerone['espiritudelsur'].childImageSharp.fluid} />}
    //                       </div>

    //                     </div>
    //                   </div>
    //                   <div className={styles.arrowParticipate}>

    //                     {isMobile ?
    //                       images.Cicerone && <Img fluid={images.Cicerone['mobilearrow'].childImageSharp.fluid} />
    //                       : images.Cicerone && <Img fluid={images.Cicerone['arrowDesktop'].childImageSharp.fluid} />}


    //                   </div>

    //                   <div className={styles.participate}>
    //                     <button onClick={() => setToggle(!toggle)}>Participar</button>
    //                   </div>
    //                 </div>

    //               </div>

    //               <div className={styles.more}>
    //                 <a href={"https://www.cicerone.org/int-es/products/beersavvy"}>Conocer más sobre el curso</a>
    //                 <p>Este sorteo es organizado exclusivamente por Cerveza Patagonia</p>
    //               </div>
    //             </>
    //           )
    //         }
    //         { toggle && !isMobile
    //           ? (<div className={styles.formIn}>
    //             {images.Cicerone && <Img className={styles.logoPatagonia} fluid={images.Cicerone['patagonia'].childImageSharp.fluid} />}
    //             <Form success={success} setSuccess={setSuccess} setToggle={setToggle} toggle={toggle} className={styles.formDesktop} />
    //           </div>
    //           ) : null}

    //         <div className={styles.footerContainer}>
    //           <FooterCicerone />
    //         </div>
    //       </div>
    //     )):(
    //       <BirthConsent handleConsent={handleConsent} />
    //     )
  )
}
export default Cicerone;
export const data = graphql`
query ImgCicerone {
    Cicerone: allFile(filter: { relativeDirectory: { eq: "cicerone" } }) {
      edges {
        node {
          name
          base
          childImageSharp {
            fluid(quality: 60, maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;


