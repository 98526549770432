import React from "react"
import PropTypes from "prop-types"
import styles from '../../styles/cicerone.module.scss';

export default class FooterCicerone extends React.Component {

  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {

  }

  render() {
    return (
      <div className={'footerWrapper'}>
        <div className={styles.footerWrapper}>
          <div className={'footerTermsWrapper'}>
            <div className={'footerSocialInfo'}>
              <div>
                <a href={'../../docs/TyC.PDF'} className={'footerLink footerLinkText'} >Términos y condiciones</a>
                <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
                <a href={'../../docs/POL.PDF'} className={'footerLink footerLinkText'} >Políticas de privacidad</a>
                <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
                <a href={'http://franquiciaspatagonia.com.ar/'} className={'footerLink footerLinkText'}>Franquicias</a>
                <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
                <a href={'https://www.cerveceriaymalteriaquilmes.com/'} className={'footerLink footerLinkText'}>Información oficial de la compañía</a>
                <span className={'footerLink footerLinkText footerLinkSeparator'}>|</span>
                <a href={'../../docs/BASESYCONDICIONESDELAPROMOCIONCURSOCERVECERO.pdf'} className={'footerLink footerLinkText'}>Bases y condiciones</a>
                <div className={'footerMessage'}>BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS. NO COMPARTA EL CONTENIDO CON MENORES</div>
              </div>

            </div>
          </div>
        </div>
      </div>

    )
  }
}