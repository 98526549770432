import React, { useState, useEffect } from 'react';
import styles from '../../styles/cicerone.module.scss';
import FormButtonSpinner from "./Spinner"
import circleunchecked from "../../images/cicerone/circleunchecked.png";
import circlechecked from "../../images/cicerone/circlechecked.png";
import Cookies from "js-cookie";


const FormCicierone = ({ success, setSuccess, toggle, setToggle }) => {
    const [fullname, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [province, setProvince] = useState('');
    const [variety, setVariety] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [error, setError] = useState(false);
    const [warning, setWarning] = useState(false);
    const [known, setKnown] = useState('');
    const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)

    const handleSelect = (e) => {
        const option = e.target.value;
        const name = e.target.name;
        if (name === 'province') setProvince(option)
        if (name === 'variety') setVariety(option)
        return province + variety
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let td = Cookies.get("_td");
        if (checkbox) {
            setShowLoadingSpinner(true)
            fetch("https://wapatagoniacicerone-prd.azurewebsites.net/form", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ fullname, email, state: province, favorite: variety, known, td }),
            }).then((response) => {
                if (response.ok) {
                    // navigate("/quiz", { state: { continue: true } });
                    setSuccess(!success)
                    setToggle(false)
                    console.log("ok")
                } else {
                    setShowLoadingSpinner(false)
                    setError("Este email ya está registrado");
                }
            });
        } else {
            setWarning("Para poder registrarte debes aceptar los términos y condiciones")
        }
    }


    const handleCheck = () => {
        setCheckbox(!checkbox)
        setError(false)
        setWarning(false)
    }
    return (
        <div className={styles.formContainer}>
            <div className={styles.crossContainer} onClick={() => setToggle(false)}>
                <span className={styles.cross1}></span>
                <span className={styles.cross2}></span>
            </div>
            <form onSubmit={handleSubmit} className={styles.form}>
                <p className={styles.formTitle}>Registrate para <br /> participar</p>
                <input required className={styles.input} placeholder={"Nombre y Apellido"} onChange={(e) => setFullName(e.target.value)} />
                <input required type="email" className={styles.input} placeholder={"Email"} onChange={(e) => setEmail(e.target.value)} />
                <div className={styles.flecha}>
                    <label className={styles.inputLabels}>
                        <select defaultValue="" onChange={e => setProvince(e.target.value)} id="province"  name="province" required>
                            <option disabled value="">Provincia</option>
                            <option value="CABA">CABA</option>
                            <option value="Buenos Aires">Buenos Aires</option>
                            <option value="Catamarca">Catamarca</option>
                            <option value="Chaco">Chaco</option>
                            <option value="Chubut">Chubut</option>
                            <option value="Córdoba">Córdoba</option>
                            <option value="Corrientes">Corrientes</option>
                            <option value="Entre Ríos">Entre Ríos</option>
                            <option value="Formosa">Formosa</option>
                            <option value="Jujuy">Jujuy</option>
                            <option value="La Rioja">La Rioja</option>
                            <option value="La Pampa">La Pampa</option>
                            <option value="Mendoza">Mendoza</option>
                            <option value="Misiones">Misiones</option>
                            <option value="Neuquén">Neuquén</option>
                            <option value="Río Negro">Rio Negro</option>
                            <option value="Salta">Salta</option>
                            <option value="San Juan">San Juan</option>
                            <option value="San Luis">San Luis</option>
                            <option value="Santa Cruz">Santa Cruz</option>
                            <option value="Santa Fe">Santa Fe</option>
                            <option value="Santiago del Estero">Santiago del Estero</option>
                            <option value="Tierra del Fuego">Tierra del Fuego</option>
                            <option value="Tucumán">Tucumán</option>
                        </select>
                    </label>
                </div>
                <div className={styles.flecha2}>
                    <label>
                        <select required defaultValue="" onChange={e => setVariety(e.target.value)} id="favorite">
                            <option disabled value="">¿Cual es tu variedad preferida de Patagonia?</option>
                            <option value="KM 24.7">KM 24.7</option>
                            <option value="Amber Lager">Amber Lager</option>
                            <option value="Bohemian Pilsner">Bohemian Pilsner</option>
                            <option value="Küne">Küne</option>
                            <option value="Weisse">Weisse</option>
                            <option value="Vera IPA">Vera IPA</option>
                            <option value="Solcitra">Solcitra</option>
                            <option value="Abrazo de Oso">Abrazo de Oso</option>
                            <option value="Otra">Otra</option>
                        </select>
                    </label>
                </div>
                <div className={styles.flecha2}>
                    <label>
                        <select required defaultValue="" onChange={e => setKnown(e.target.value)} id="known">
                            <option disabled value=""> ¿Qué tanto conoces del documental Espíritu del Sur?</option>
                            <option value="first">No escuché hablar sobre el documental Espíritu del Sur</option>
                            <option value="second">Escuché hablar pero no vi ningún contenido</option>
                            <option value="third">Vi sus contenidos en la televisión, redes sociales o el sitio web</option>
                        </select>
                    </label>
                </div>

                <div className={styles.button}>
                    {showLoadingSpinner ? (
                        <FormButtonSpinner />
                    ) : (

                        <button
                            type="submit"
                            className={styles.registerCicerone}>Registrarme</button>
                    )}
                    {warning ? <div className={styles.warning}>{warning}</div> : null}
                    {error ? <div className={styles.error}>{error}</div> : null}
                </div>

                <div className={styles.checkbox}>
                    <img onClick={handleCheck} className={styles.radio} alt={'checkbox'} src={checkbox ? circlechecked : circleunchecked} />
                    <p for="checkbox"> He leído y acepto <a href={'../../docs/BASESYCONDICIONESDELAPROMOCIONCURSOCERVECERO.pdf'}>términos y condiciones, políticas de privacidad</a> y activaciones <br className={styles.br} /> de marketing.</p>
                </div>
            </form >
        </div>
    )
}


export default FormCicierone;