export const IsMobileDevice = () => { //return if device is mobile
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
}
//return a array of element clean of a consult grasphql (allFiles)
export const CleanArrayImages = (data) => {
    const keys = Object.keys(data);
    const Images = {};
    keys.forEach(key => {
        data[key].edges.forEach(img => {
            Images[key] = {
                ...Images[key],
                [img.node.name]: img.node
            }
        })
    })
    return Images;
}