import React from "react";
//styles
import spinners from "../../styles/spinner.module.scss";

const FormButtonSpinner = () => {
    return (
        <div className={spinners.smallSpinnerBox}>
            <div className={spinners.smallCircleBorder}>
                <div className={spinners.smallCircleCore}></div>
            </div>
        </div>
    );
};

export default FormButtonSpinner;
